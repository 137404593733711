import Vue from "vue";
import Router from "vue-router";
import clientauth from "./clientauth";
import backendRoute from "./backend";

Vue.use(Router);

var router = new Router({
  mode: "history",
  routes: [
    ...clientauth,
    {
      path: "",
      name: "client",
      component: () => import("@/components/layouts/app"),
      children: [...backendRoute],
    },
    {
      path: "*",
      name: "page.not.found",
      component: () => import("@/pages/404"),
    },
    {
      path: "/page-not-found",
      name: "error.page",
      component: () => import("@/pages/404"),
    },
    {
      path: "*",
      name: "permission.denied",
      component: () => import("@/pages/403"),
    },
  ],
});

export default router;
