import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
import {
  FETCH_B_USER,
  STORE_B_USER,
  UPDATE_B_USER,
  DELETE_B_USER,
  GET_B_USER_DATA,
  GET_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_PASSWORD,
  EMAIL_PASSWORD_B_USER,
  UPDATE_USER_DETAIL,
  STORE_ADMIN_USER,
  ADD_USER_DETAIL,
  FETCH_ORGANIZATION_USER,
  GET_ORGANIZATION_USER_DATA,
  DELETE_ORGANIZATION_USER,
  GET_MODULES,
} from "../actions.type";
// mutation types
import {
  SET_B_USERS,
  SET_ORGANIZATION_USERS,
  SET_ERROR,
} from "../mutations.type";

const state = {
  errors: null,
  users: [],
  organization_users: [],
};

const getters = {
  getUsers(state) {
    return state.users;
  },
  getOrganizationUsers(state) {
    return state.organization_users;
  },
};

const actions = {
  [UPDATE_USER_DETAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/location-user/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [ADD_USER_DETAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("add/client/users/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_B_USER](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/user/lists")
          .then(({ data }) => {
            context.commit(SET_B_USERS, data.data);
            resolve(data.data);
          })
          .catch(({ err }) => {
            context.commit(SET_ERROR, err);
            reject(err);
          });
      });
    }
  },
  [FETCH_ORGANIZATION_USER](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/roles-users/lists")
          .then(({ data }) => {
            context.commit(SET_ORGANIZATION_USERS, data.data);
            resolve(data.data);
          })
          .catch(({ err }) => {
            context.commit(SET_ERROR, err);
            reject(err);
          });
      });
    }
  },
  [STORE_B_USER](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/user/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_B_USER](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/user/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_ADMIN_USER](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/admin-user/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_B_USER](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/user-role/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_B_USER](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("delete/user/" + id + "/")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_ORGANIZATION_USER](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("delete/user/" + id + "/")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_B_USER_DATA](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/user/", id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_ORGANIZATION_USER_DATA](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/users/detail/" + id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_MODULES]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/modules/lists")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [GET_PROFILE_DATA]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/profile")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_PROFILE_DATA](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/profile/", data.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_PROFILE_PASSWORD](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.put("update/password/", data.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [EMAIL_PASSWORD_B_USER](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("user/set-password/", {
          user_id: data.user_id,
          password: data.password,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
};

const mutations = {
  [SET_B_USERS](state, data) {
    state.users = data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ORGANIZATION_USERS](state, data) {
    state.organization_users = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
