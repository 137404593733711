//auth module
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CHECK_AUTH = "checkAuth";
export const FETCH_USER_PERMISSIONS = "fetchUserPermissions";

//config module
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";

//htmlclass module
export const ADD_BODY_CLASSNAME = "addBodyClassName";
export const REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export const ADD_CLASSNAME = "addClassName";

//breadcrumb module
export const APPEND_BREADCRUM = "appendBreadcrumb";

//user

export const FETCH_B_USER = "FETCH_B_USER";
export const STORE_B_USER = "STORE_B_USER";
export const UPDATE_B_USER = "UPDATE_B_USER";
export const DELETE_B_USER = "DELETE_B_USER";
export const GET_B_USER_DATA = "GET_B_USER_DATA";

export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL";

//profile

export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";

//setting
export const FETCH_F_NOTE = "FETCH_F_NOTE";
export const UPDATE_PROFILE_PASSWORD = "UPDATE_PROFILE_PASSWORD";
export const EMAIL_PASSWORD_B_USER = "EMAIL_PASSWORD_B_USER";
export const FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE =
  "FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE";
export const STORE_SMTP_OUTGOING_EMAIL_CONFIGURE =
  "STORE_SMTP_OUTGOING_EMAIL_CONFIGURE";
export const CHECK_SMTP_CONNECTION = "CHECK_SMTP_CONNECTION";

export const FETCH_SMS_CONFIG = "FETCH_SMS_CONFIG";
export const SUBMIT_SMS_CONFIG = "SUBMIT_SMS_CONFIG";

export const FETCH_MAP_CONFIG = "FETCH_MAP_CONFIG";
export const SUBMIT_MAP_CONFIG = "SUBMIT_MAP_CONFIG";

//sp
export const FETCH_SERVICE_PROVIDER_DATA = "FETCH_SERVICE_PROVIDER_DATA";

//auth
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";

export const STORE_ADMIN_USER = "STORE_ADMIN_USER";

// DASHBOARD
export const GET_DASHBOARD_CARD_DATA = "GET_DASHBOARD_CARD_DATA";
export const FETCH_DATA_FOR_GRAPH = "FETCH_DATA_FOR_GRAPH";
export const FETCH_APPOITMENT_DATA_FOR_GRAPH =
  "FETCH_APPOITMENT_DATA_FOR_GRAPH";
export const FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH =
  "FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH";

export const EXPORT_DATA = "EXPORT_DATA";

export const ADD_USER_DETAIL = "ADD_USER_DETAIL";

export const REFRESH = "REFRESH";

// oven report
export const STORE_DAILY_OVEN_REPORT = "STORE_DAILY_OVEN_REPORT";
export const FETCH_DAILY_OVEN_REPORTS = "FETCH_DAILY_OVEN_REPORTS";
export const FETCH_SELF_OVEN_REPORT = "FETCH_SELF_OVEN_REPORT";
export const DELETE_DAILY_OVEN_REPORT = "DELETE_DAILY_OVEN_REPORT";
export const UPDATE_DAILY_OVEN_REPORT = "UPDATE_DAILY_OVEN_REPORT";

export const FETCH_ORGANIZATION_USER = "FETCH_ORGANIZATION_USER";
export const GET_ORGANIZATION_USER_DATA = "GET_ORGANIZATION_USER_DATA";
export const DELETE_ORGANIZATION_USER = "DELETE_ORGANIZATION_USER";

//modules
export const GET_MODULES = "GET_MODULES";

// mixing report
export const FETCH_DAILY_MIXING_REPORTS = "FETCH_DAILY_MIXING_REPORTS";
export const FETCH_SELF_MIXING_REPORT = "FETCH_SELF_MIXING_REPORT";
export const DELETE_DAILY_MIXING_REPORT = "DELETE_DAILY_MIXING_REPORT";
export const STORE_DAILY_MIXING_REPORT = "STORE_DAILY_MIXING_REPORT";
export const UPDATE_DAILY_MIXING_REPORT = "UPDATE_DAILY_MIXING_REPORT";

// raw material report
export const FETCH_RAW_MATERIAL_REPORTS = "FETCH_RAW_MATERIAL_REPORTS";
export const FETCH_SELF_RAW_MATERIAL_REPORT = "FETCH_SELF_RAW_MATERIAL_REPORT";
export const DELETE_RAW_MATERIAL_REPORT = "DELETE_RAW_MATERIAL_REPORT";
export const STORE_RAW_MATERIAL_REPORT = "STORE_RAW_MATERIAL_REPORT";
export const UPDATE_RAW_MATERIAL_REPORT = "UPDATE_RAW_MATERIAL_REPORT";

// packing material report
export const FETCH_PACKING_MATERIAL_REPORTS = "FETCH_PACKING_MATERIAL_REPORTS";
export const FETCH_SELF_PACKING_MATERIAL_REPORT =
  "FETCH_SELF_PACKING_MATERIAL_REPORT";
export const DELETE_PACKING_MATERIAL_REPORT = "DELETE_PACKING_MATERIAL_REPORT";
export const STORE_PACKING_MATERIAL_REPORT = "STORE_PACKING_MATERIAL_REPORT";
export const UPDATE_PACKING_MATERIAL_REPORT = "UPDATE_PACKING_MATERIAL_REPORT";

export const FETCH_BISCUITS_LISTS = "FETCH_BISCUITS_LISTS";
export const STORE_FINISHED_GOODS_REPORT = "STORE_FINISHED_GOODS_REPORT";

export const FETCH_FINISHED_GOODS_ANALYSIS_REPORT =
  "FETCH_FINISHED_GOODS_ANALYSIS_REPORT";
export const DELETE_FINISHED_GOODS_ANALYSIS_REPORT =
  "DELETE_FINISHED_GOODS_ANALYSIS_REPORT";
export const FETCH_FINISHED_GOODS_DETAIL_LISTS =
  "FETCH_FINISHED_GOODS_DETAIL_LISTS";
export const UPDATE_FINISHED_GOODS_REPORT = "UPDATE_FINISHED_GOODS_REPORT";
export const FETCH_SELF_FINISHED_GOODS_REPORT =
  "FETCH_SELF_FINISHED_GOODS_REPORT";
export const ADD_BISCUITS_DETAIL = "ADD_BISCUITS_DETAIL";
export const DELETE_BISCUITS_DETAIL = "DELETE_BISCUITS_DETAIL";

export const FETCH_VISITOR_LOGS = "FETCH_VISITOR_LOGS";
export const FETCH_VISITOR = "FETCH_VISITOR";
export const STORE_VISITOR_LOG = "STORE_VISITOR_LOG";
export const DELETE_VISIT = "DELETE_VISIT";

export const FETCH_MATERIAL_LISTS = "FETCH_MATERIAL_LISTS";
export const FETCH_MATERIAL_TYPE_LISTS = "FETCH_MATERIAL_TYPE_LISTS";

export const CREATE_MATERIAL_DETAILS = "CREATE_MATERIAL_DETAILS";
export const FETCH_SELF_MATERIAL_DETAIL = "FETCH_SELF_MATERIAL_DETAIL";
export const ADD_SUPPLIER_DETAIL = "ADD_SUPPLIER_DETAIL";

export const FETCH_ALL_MATERIAL_LIST = "FETCH_ALL_MATERIAL_LIST";

export const DELETE_MATERIAL = "DELETE_MATERIAL";










