export default [
  {
    path: "",
    name: "client.dashboard",
    component: () => import("@/pages/backend/dashboard"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: false,
    },
  },
  {
    path: "/oven-reports",
    name: "client.dailyovenreports",
    component: () => import("@/pages/backend/ovenreport/index"),
    meta: {
      requiresAuth: true,
      permission: "oven_report",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/oven-reports/create",
    name: "client.ovenreport.create",
    component: () => import("@/pages/backend/ovenreport/form"),
    meta: {
      requiresAuth: true,
      permission: "oven_report",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/oven-reports/:id/edit",
    name: "client.ovenreport.edit",
    component: () => import("@/pages/backend/ovenreport/form"),
    meta: {
      requiresAuth: true,
      permission: "oven_report",
      is_client: true,
      is_service_provider: false,
    },
  },
  {
    path: "/mixing",
    name: "client.dailymixing",
    component: () => import("@/pages/backend/mixingreport/index"),
    meta: {
      requiresAuth: true,
      permission: "mixing_report",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/mixing/create",
    name: "client.dailymixing.create",
    component: () => import("@/pages/backend/mixingreport/form"),
    meta: {
      requiresAuth: true,
      permission: "mixing_report",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/mixing/:id/edit",
    name: "client.dailymixing.edit",
    component: () => import("@/pages/backend/mixingreport/form"),
    meta: {
      requiresAuth: true,
      permission: "mixing_report",
      is_client: true,
      is_service_provider: false,
    },
  },
  {
    path: "/qualitycontrol/packing-materials",
    name: "client.qualitycontrol.packingmaterials",
    component: () =>
      import("@/pages/backend/qualitycontrol/packingmaterial/index"),
    meta: {
      requiresAuth: true,
      permission: "packing_material",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/qualitycontrol/packing-materials/create",
    name: "client.qualitycontrol.packingmaterials.create",
    component: () =>
      import("@/pages/backend/qualitycontrol/packingmaterial/form"),
    meta: {
      requiresAuth: true,
      permission: "packing_material",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/qualitycontrol/packing-materials/:id/edit",
    name: "client.qualitycontrol.packingmaterials.edit",
    component: () =>
      import("@/pages/backend/qualitycontrol/packingmaterial/form"),
    meta: {
      requiresAuth: true,
      permission: "packing_material",
      is_client: true,
      is_service_provider: false,
    },
  },
  {
    path: "/qualitycontrol/raw-materials",
    name: "client.qualitycontrol.rawmaterials",
    component: () => import("@/pages/backend/qualitycontrol/rawmaterial/index"),
    meta: {
      requiresAuth: true,
      permission: "raw_material",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/qualitycontrol/raw-materials/create",
    name: "client.qualitycontrol.rawmaterials.create",
    component: () => import("@/pages/backend/qualitycontrol/rawmaterial/form"),
    meta: {
      requiresAuth: true,
      permission: "raw_material",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/qualitycontrol/raw-materials/:id/edit",
    name: "client.qualitycontrol.rawmaterials.edit",
    component: () => import("@/pages/backend/qualitycontrol/rawmaterial/form"),
    meta: {
      requiresAuth: true,
      permission: "raw_material",
      is_client: true,
      is_service_provider: false,
    },
  },
  {
    path: "/qualitycontrol/finished-goods",
    name: "client.qualitycontrol.finishedgoods",
    component: () =>
      import("@/pages/backend/qualitycontrol/finishedgoods/index"),
    meta: {
      requiresAuth: true,
      permission: "finished_goods",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/biscuits",
    name: "client.biscuits",
    component: () => import("@/pages/backend/biscuits/index"),
    meta: {
      requiresAuth: true,
      permission: "",
    },
  },
  {
    path: "/qualitycontrol/finished-goods/create",
    name: "client.qualitycontrol.finishedgoods.create",
    component: () =>
      import("@/pages/backend/qualitycontrol/finishedgoods/form"),
    meta: {
      requiresAuth: true,
      permission: "finished_goods",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/qualitycontrol/finished-goods/:id/edit",
    name: "client.qualitycontrol.finishedgoods.edit",
    component: () =>
      import("@/pages/backend/qualitycontrol/finishedgoods/updateform"),
    meta: {
      requiresAuth: true,
      permission: "finished_goods",
      is_client: true,
      is_service_provider: false,
    },
  },

  {
    path: "/users",
    name: "client.user",
    component: () => import("@/pages/backend/user/index"),
    meta: {
      requiresAuth: true,
      permission: "",
    },
  },
  {
    path: "/visitorlog",
    name: "client.visitorlogs",
    component: () => import("@/pages/backend/visitorlogs/index"),
    meta: {
      requiresAuth: true,
      permission: "visitor_log",
    },
  },
  {
    path: "/visitorlog/:id/create",
    name: "client.visitorlogs.create",
    component: () => import("@/pages/backend/visitorlogs/form"),
    meta: {
      requiresAuth: true,
      permission: "visitor_log",
    },
  },
  {
    path: "/visitorlog/:id/edit",
    name: "client.visitorlogs.edit",
    component: () => import("@/pages/backend/visitorlogs/form"),
    meta: {
      requiresAuth: true,
      permission: "visitor_log",
    },
  },

  {
    path: "/user/:id/create",
    name: "client.user.create",
    component: () => import("@/pages/backend/user/form"),
    meta: {
      requiresAuth: true,
      permission: "",
    },
  },
  {
    path: "/user/:id/edit",
    name: "client.user.edit",
    component: () => import("@/pages/backend/user/form"),
    meta: {
      requiresAuth: true,
      permission: "",
    },
  },

  {
    path: "/change-password",
    name: "client.changepassword",
    component: () => import("@/pages/backend/settings/profile"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true,
    },
  },

  {
    path: "/admin/dashboard",
    name: "admin.dashboard",
    component: () => import("@/pages/backend/dashboard"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true,
    },
  },

  {
    path: "/admin/profile",
    name: "admin.profile",
    component: () => import("@/pages/backend/settings/profile"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true,
    },
  },

  {
    path: "/admin/users",
    name: "admin.user",
    component: () => import("@/pages/backend/user/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true,
    },
  },
  {
    path: "/admin/user/create",
    name: "admin.user.create",
    component: () => import("@/pages/backend/user/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true,
    },
  },
  {
    path: "/materials",
    name: "client.materials",
    component: () =>
      import("@/pages/backend/settings/material_n_supplier/material/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true,
    },
  },
  {
    path: "/materials/:id/create",
    name: "client.materials.create",
    component: () =>
      import("@/pages/backend/settings/material_n_supplier/material/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: false,
      is_admin: false,
    },
  },
  {
    path: "/materials/:id/edit",
    name: "client.materials.edit",
    component: () =>
      import("@/pages/backend/settings/material_n_supplier/material/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: false,
    },
  },

  {
    path: "/suppliers",
    name: "client.suppliers",
    component: () =>
      import("@/pages/backend/settings/material_n_supplier/supplier/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true,
    },
  },
];
