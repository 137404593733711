//auth module
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";
export const SET_USER = "setUser";
export const SET_USER_PERMISSIONS = "setUserPermissions";
export const SET_SERVICE_NAME = "SET_SERVICE_NAME";

//htmclass module
export const SET_CLASSNAME_BY_POSITION = "setClassNameByPosition";

//breadcrumb
export const SET_BREADCRUMB = "setBreadcrumb";
export const ADD_BREADCRUMB = "addBreadcrumb";


//spinner
export const SET_LOADING_SPINNER = "SET_LOADING_SPINNER";
export const SET_TIME_ZONE = "SET_TIME_ZONE";

//User
export const SET_B_USERS = "SET_B_USERS";

// DASHBOARD
export const SET_DASHBOARD_CARD_DATA = "SET_DASHBOARD_CARD_DATA";
export const SET_SEARCH = "SET_SEARCH";
export const SET_ORGANIZATION_USERS = "SET_ORGANIZATION_USERS";