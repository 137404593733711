import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import auth from "./modules/auth.module";
import config from "./modules/config.module";
import htmlClass from "./modules/htmlclass.module";
import Appointment from "./modules/appointment.module";
import User from "./modules/user.module";
import dashboadModule from "./modules/dashboad.module";

export default new Vuex.Store({
  modules: {
    auth,
    config,
    htmlClass,
    Appointment,
    User,
    dashboadModule,
   
  }
});
