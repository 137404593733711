import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import {
  STORE_DAILY_OVEN_REPORT,
  FETCH_DAILY_OVEN_REPORTS,
  FETCH_SELF_OVEN_REPORT,
  DELETE_DAILY_OVEN_REPORT,
  UPDATE_DAILY_OVEN_REPORT,
  STORE_DAILY_MIXING_REPORT,
  UPDATE_DAILY_MIXING_REPORT,
  FETCH_DAILY_MIXING_REPORTS,
  FETCH_SELF_MIXING_REPORT,
  DELETE_DAILY_MIXING_REPORT,
  STORE_RAW_MATERIAL_REPORT,
  UPDATE_RAW_MATERIAL_REPORT,
  FETCH_RAW_MATERIAL_REPORTS,
  FETCH_SELF_RAW_MATERIAL_REPORT,
  DELETE_RAW_MATERIAL_REPORT,
  STORE_PACKING_MATERIAL_REPORT,
  UPDATE_PACKING_MATERIAL_REPORT,
  FETCH_PACKING_MATERIAL_REPORTS,
  FETCH_SELF_PACKING_MATERIAL_REPORT,
  DELETE_PACKING_MATERIAL_REPORT,
  FETCH_BISCUITS_LISTS,
  ADD_BISCUITS_DETAIL,
  DELETE_BISCUITS_DETAIL,
  STORE_FINISHED_GOODS_REPORT,
  FETCH_FINISHED_GOODS_ANALYSIS_REPORT,
  DELETE_FINISHED_GOODS_ANALYSIS_REPORT,
  FETCH_FINISHED_GOODS_DETAIL_LISTS,
  UPDATE_FINISHED_GOODS_REPORT,
  FETCH_SELF_FINISHED_GOODS_REPORT,
  FETCH_VISITOR_LOGS,
  FETCH_VISITOR,
  STORE_VISITOR_LOG,
  DELETE_VISIT,
  FETCH_MATERIAL_LISTS,
  FETCH_MATERIAL_TYPE_LISTS,
  CREATE_MATERIAL_DETAILS,
  FETCH_SELF_MATERIAL_DETAIL,
  ADD_SUPPLIER_DETAIL,
  FETCH_ALL_MATERIAL_LIST,
  DELETE_MATERIAL
} from "../actions.type";

// import {
//   SET_F_CLIENTS,
//   SET_ERROR,
//   SET_F_CLIENT_NAME,
//   SET_F_CLIENT_RATE,
//   SET_F_ALERT_MSG,
//   SET_RATE_TOTAL_AMOUNT,
//   SET_ADD_RATE_QTY,
//   SET_CHECK_F_ABBREVIATION_CODE,
//   UPDATE_ABBREVIATION_CODE
// } from "../mutations.type";

const actions = {
  [STORE_DAILY_OVEN_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/daily-oven-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_DAILY_OVEN_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/daily-oven-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_DAILY_OVEN_REPORTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("get/daily-oven-report/lists/?page=" + data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SELF_OVEN_REPORT](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/oven-report-detail/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_DAILY_OVEN_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/daily-oven-report/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  // mixing report

  [STORE_DAILY_MIXING_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/daily-mixing-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_DAILY_MIXING_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/daily-mixing-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_DAILY_MIXING_REPORTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/daily-mixing-report/lists/?page=` + data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SELF_MIXING_REPORT](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/mixing-detail/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_DAILY_MIXING_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/daily-mixing-report/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_RAW_MATERIAL_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/raw-material-inspection-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_RAW_MATERIAL_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/raw-material-inspection-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_RAW_MATERIAL_REPORTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/raw-material-inspection/lists/?page=` + data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SELF_RAW_MATERIAL_REPORT](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/raw-material-inspection/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_RAW_MATERIAL_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/raw-material-inspection-report/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_BISCUITS_LISTS]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/biscuit/list")
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_ALL_MATERIAL_LIST]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/all/material")
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  
  [ADD_BISCUITS_DETAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/biscuit/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [ADD_SUPPLIER_DETAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/supplier/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [DELETE_BISCUITS_DETAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/biscuit/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [STORE_FINISHED_GOODS_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/finished-goods-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  // finished go0ds
  [DELETE_FINISHED_GOODS_ANALYSIS_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/finished-goods/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_FINISHED_GOODS_ANALYSIS_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/finished-goods/list/?page=` + data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  // get/material/list<str:supplier>/</str:supplier>
  [FETCH_MATERIAL_LISTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(
          `get/material/list/` + data.supplier + `/?page=` + data.page
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_MATERIAL_TYPE_LISTS]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/material-type`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [CREATE_MATERIAL_DETAILS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/material/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [STORE_PACKING_MATERIAL_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/packing-material-analysis-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [UPDATE_PACKING_MATERIAL_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/packing-material-analysis-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_FINISHED_GOODS_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("update/finished-goods-report/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [FETCH_PACKING_MATERIAL_REPORTS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/packing-material-analysis/lists/?page=` + data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SELF_PACKING_MATERIAL_REPORT](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/packing-material-analysis/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SELF_FINISHED_GOODS_REPORT](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/finished-goods/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SELF_MATERIAL_DETAIL](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/material-detail/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [FETCH_FINISHED_GOODS_DETAIL_LISTS](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/finished-goods/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_PACKING_MATERIAL_REPORT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/packing-material-analysis-report/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [FETCH_VISITOR_LOGS](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post(`get/visitor-log/lists/?page=` + data.page, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_VISITOR](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`get/visitor-log/${id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [STORE_VISITOR_LOG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/visitor-log/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_VISIT](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/visitlog/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_MATERIAL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`delete/material/${data}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  
};

export default {
  actions,
};
